import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Auth/Login.vue';
import RecuperarSenha from '@/views/Auth/RecuperarSenha.vue';
import VerificacaoWhatsApp from '@/views/Auth/VerificacaoWhatsApp.vue';
import Cotacoes from '@/views/Cotacoes.vue';
import EventRegister from '@/views/EventRegister.vue';
import VisualizarCotacao from '@/views/Cotacao/VisualizarCotacao.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      hideNavbar: true,
      allowAnonymous: true
    }
  },
  {
    path: '/senha/:passwordToken',
    name: 'Password',
    component: Login,
    meta: {
      hideNavbar: true,
      allowAnonymous: true
    }
  },
  {
    path: '/recuperar-senha',
    name: 'RecuperarSenha',
    component: RecuperarSenha,
    meta: {
      hideNavbar: true,
      allowAnonymous: true
    }
  },
  {
    path: '/enviar-link/:cpfCnpj',
    name: 'VerificacaoWhatsApp',
    component: VerificacaoWhatsApp,
    meta: {
      hideNavbar: true,
      allowAnonymous: true
    }
  },
  {
    path: '/cotacoes',
    name: 'Home',
    component: Cotacoes,
  },
  {
    path: '/comunicar-evento/:id',
    name: 'EventRegister',
    component: EventRegister,
  },
  {
    path: '/visualizar-cotacao/:id',
    name: 'VisualizarCotacao',
    component: VisualizarCotacao,
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
