import ApiService from './api.service';

const route = 'api/account';

const AccountService = {
  validateToken: async (token) => {
    const response = await ApiService.get(`${route}/validate/token/${token}`);
    return response.data;
  },
  newPassword: async (token, password) => {
    const response = await ApiService.put(`${route}/password/new/${token}`, password);
    return response.data;
  },
  resetPassword: async (email) => {
    const response = await ApiService.get(`${route}/password/reset`, email);
    return response.data;
  },
  // get: async (cpfCnpj) => {
  //   const response = await ApiService.get(`${route}/email`, cpfCnpj);
  //   return response.data;
  // },
  sendRecoverPasswordMessage: async (cpfCnpj) => {
    const response = await ApiService.get(`${route}/password/reset/${cpfCnpj}/phone`);
    return response.data;
  }
};

export default AccountService;
