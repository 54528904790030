const normalize = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const formatPhoneNumber = (phone) => {
  if (!phone) {
    return null;
  }

  return phone.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3');
};

export {
  normalize,
  formatPhoneNumber,
};
