export default {
  PRIMEIRO_ANO_SIM: 1,
  PRIMEIRO_ANO_NAO: 2,
  POSSUI_SEGURO_SIM: 3,
  POSSUI_SEGURO_NAO: 4,
  CULTURA_IMPLANTADA_SIM: 5,
  CULTURA_IMPLANTADA_NAO: 6,
  PRODUTOR_TEVE_PREJUIZOS_SIM: 7,
  PRODUTOR_TEVE_PREJUIZOS_NAO: 8
};
