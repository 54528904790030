<template lang="pug">
auth(title='Não consigo entrar')
  template(v-slot:content)
    v-form#form(ref='form', @keyup.native.enter='recoverPassword', @submit='recoverPassword')
      v-text-field#cpfCnpj(
        v-model="cpfCnpj",
        label="CPF/CNPJ",
        color="#FFF",
        dark,
        v-mask="mask",
        @input="maskValue",
        :rules="[rules.required, rules.cpfCnpj]"
      )

      .submit-container
        .btn-back(@click="goBack")
          v-icon#buttonBackLogin(color="#FFFFFF" small) mdi-arrow-left
          a#buttonBackLogin Voltar à tela inicial

        v-btn.submit-button#buttonAdvance(
          dark
          elevation="0"
          tile
          outlined
          @click="recoverPassword"
        )
          span Avançar
          v-icon mdi-arrow-right
</template>

<script>
// import AccountService from '@/services/account.service';
import Auth from './Auth.vue';

const CPF_MASK = '###.###.###-##';
const CNPJ_MASK = '##.###.###/####-##';

export default {
  name: 'RecuperarSenha',
  components: {
    Auth
  },
  data() {
    return {
      cpfCnpj: null,
      mask: CPF_MASK,
      rules: {
        required: (value) => !!value || 'Campo obrigatório.',
        cpfCnpj: (value) => {
          const pattern = /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/;
          return pattern.test(value) || (value?.length < 15 ? 'CPF inválido.' : 'CNPJ inválido.');
        },
      },
    };
  },
  methods: {
    maskValue() {
      if (this.cpfCnpj && this.cpfCnpj.replace(/[^\w\s]/gi, '').length > 11) {
        this.mask = CNPJ_MASK;
        return;
      }

      this.mask = CPF_MASK;
    },
    async recoverPassword() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: 'Verifique o preenchimento do campo.',
        });
        return;
      }
      try {
        const cpfCnpj = this.cpfCnpj.replace(/[^\d]+/g, '');
        // await AccountService.get(cpfCnpj);
        this.$router.push({ name: 'VerificacaoWhatsApp', params: { cpfCnpj } });
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: e.message || 'CPF/CNPJ não está cadastrado no sistema.',
        });
      }
    },
    goBack() {
      this.$router.push({
        name: 'Login',
      });
    }
  }
};
</script>
