import ApiService from './api.service';

const route = '/services/catalog/api/geo';

export default {
  getCloseCities: async (wkt, precision = 0) => {
    const response = await ApiService.get(`${route}/cities/geometry/${precision}/${wkt}`);
    return response.data;
  },
  getStates: async (code) => {
    let response;
    if (code) {
      response = await ApiService.get(`${route}/states?code=${code}`);
    } else {
      response = await ApiService.get(`${route}/states`);
    }
    return response.data;
  },
  searchCityByName: async (name) => {
    const response = await ApiService.get(`${route}/cities/name`, name);
    return response.data;
  },
  getCity: async (cityId) => {
    const response = await ApiService.get(`${route}/cities`, cityId);
    return response.data;
  },
};
