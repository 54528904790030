<template lang='pug'>
div
  include ./dados.pug
</template>

<script>
import Mapa from '@/components/Mapa/Mapa.vue';
import AreaService from '@/services/area.service';
import ProcessService from '@/services/process.service';
import ProducerLossService from '@/services/producer-loss.service';
import ChannelService from '@/services/channel.service';
import QuizService from '@/services/quiz.service';
import CatalogService from '@/services/catalog.service';
import AnswerUtil from '@/utils/answer.util';
import QuestionEnum from '@/enums/QuestionEnum';
import AlternativeEnum from '@/enums/AlternativeEnum';
import CoverageTypeEnum from '@/enums/CoverageTypeEnum';

export default {
  components: {
    Mapa,
  },
  data() {
    return {
      area: {},
      loaded: false,
      code: this.$route.params.id,
      process: null,
      channel: null,
      answers: null,
      isBracketChannel: false,
      areaAnswers: null,
      citiesSearched: {},
      quiz: null,
      optionalProducts: [],
      hasOptionalProducts: false,
      activeOptionalProducts: [],
      losses: []
    };
  },
  async mounted() {
    this.area = await AreaService.getByProcess(this.code);
    this.process = await ProcessService.getProcess(this.code);
    this.answers = await QuizService.getByProcess(this.code);
    this.channel = await ChannelService.get(this.process.channelId);
    this.isBracketChannel = this.channel.coverage.type === CoverageTypeEnum.BRACKET;

    this.handleAddress();
    this.handleQuiz();
    this.handleResultados();

    this.loadArea();
    this.loaded = true;
  },
  methods: {
    handleAddress() {
      const { issuer } = this.process;
      issuer.propertyAddress = { ...issuer.addresses[0] };
      issuer.propertyAddress.singleLine = this.createSingleLineAddress(issuer.propertyAddress);
      this.process.issuer = issuer;
    },

    handleResultados() {
      const resultados = this.process.result.productResult.processResults;
      const strike = this.process.result.strikeValue;
      this.process.produtividade = resultados.find((result) => result.strikeValue === strike && result.expressionCode === 'PRODUCTIVITY_REFERENCE').value;
      this.process.produtividadeInferior = resultados.find((result) => result.strikeValue === strike && result.expressionCode === 'BOTTOM_PRODUCTIVITY_REFERENCE')?.value;

      this.optionalProducts = this.process.result.optionalProducts;
      this.hasOptionalProducts = this.optionalProducts?.length > 0 ?? false;
      this.activeOptionalProducts = [0];
      if (this.hasOptionalProducts) {
        this.activeOptionalProducts.push(this.optionalProducts.length + 1);
      }
    },

    getOptionalProduct(optionalProduct, prop) {
      const strike = this.process.result.strikeValue;
      const value = optionalProduct.optionalProductsValues.find((values) => values.strikeValue === strike);
      return value[prop];
    },

    sumWithOptionalProduct(prop, sumWith) {
      const values = [];
      for (const optionalProduct of this.optionalProducts) {
        const value = this.getOptionalProduct(optionalProduct, prop);
        values.push(value);
      }

      return values.reduce((sum, curr) => sum + curr, Number(sumWith));
    },

    handleQuiz() {
      this.quiz = {
        primeiroAnoPlantio: AnswerUtil.findAlternative(this.answers, QuestionEnum.PRIMEIRO_ANO_PLANTIO) === AlternativeEnum.PRIMEIRO_ANO_SIM,
        possuiSeguroAgricola: AnswerUtil.findAlternative(this.answers, QuestionEnum.POSSUI_SEGURO_AGRICOLA) === AlternativeEnum.POSSUI_SEGURO_SIM,
        culturaImplantada: AnswerUtil.findAlternative(this.answers, QuestionEnum.CULTURA_IMPLANTADA) === AlternativeEnum.CULTURA_IMPLANTADA_SIM,
        produtorTevePrejuizos: AnswerUtil.findAlternative(this.answers, QuestionEnum.PRODUTOR_TEVE_PREJUIZOS) === AlternativeEnum.PRODUTOR_TEVE_PREJUIZOS_SIM,
        nomeResponsavel: AnswerUtil.findValue(this.answers, QuestionEnum.NOME_RESPONSAVEL),
        celularResponsavel: AnswerUtil.findValue(this.answers, QuestionEnum.CELULAR_RESPONSAVEL),
        observacaoResponsavel: AnswerUtil.findValue(this.answers, QuestionEnum.OBSERVACAO_RESPONSAVEL),
      };

      if (this.quiz.produtorTevePrejuizos) {
        ProducerLossService.listByProcess(this.code).then((losses) => {
          this.losses = losses.map((loss) => ({
            anoPrejuizo: loss.year,
            causaPrejuizo: loss.cause.name,
            areaPrejuizo: loss.affectedArea,
            produtividadePrejuizo: loss.obtainedProductivity,
          }));
        });
      }
    },

    createSingleLineAddress(address) {
      let singleLine = address.street;

      if (address.number !== undefined && address.number !== null) {
        singleLine += ` - ${address.number}`;
      }

      if (address.additionalInfo) {
        singleLine += ` - ${address.additionalInfo}`;
      }

      if (address.neighborhood) {
        singleLine += ` - ${address.neighborhood}`;
      }

      singleLine += ` - ${address.city.name}/${address.city.state}`;

      singleLine += ` - ${address.zipCode}`;

      return singleLine;
    },

    loadArea() {
      this.areaAnswers = {
        bagsQuantity: AnswerUtil.findAnswer(this.area.globalAnswers, QuestionEnum.SACAS_CPR)?.value,
        costPerBag: AnswerUtil.findAnswer(this.area.globalAnswers, QuestionEnum.VALOR_SACA_CPR)?.value,
        areaCpr: AnswerUtil.findAnswer(this.area.globalAnswers, QuestionEnum.AREA_CPR)?.value,
        dateStartPlanting: AnswerUtil.findAnswer(this.area.globalAnswers, QuestionEnum.DATA_PLANTIO)?.value,
        dateEndPlanting: AnswerUtil.findAnswer(this.area.globalAnswers, QuestionEnum.DATA_FIM_PLANTIO)?.value,
        dateStartHarvest: AnswerUtil.findAnswer(this.area.globalAnswers, QuestionEnum.DATA_COLHEITA)?.value,
        crop: AnswerUtil.findAnswer(this.area.fields[0].answers, QuestionEnum.CULTURA)?.value
      };

      // Separado do for abaixo para da tempo de carregar poder centralizar nos talhões após ter adicionado
      // as geometrias no mapa corretamente.
      this.area.fields.forEach(async (field) => {
        const city = await this.getCityName(AnswerUtil.findValue(field.answers, QuestionEnum.MUNICIPIO));
        field.info = {
          ...(field.info || {}),
          city
        };
      });

      for (const field of this.area.fields) {
        field.area = AnswerUtil.findValue(field.answers, QuestionEnum.AREA);

        field.info = {
          ...(field.info || {}),
          crop: AnswerUtil.findValue(field.answers, QuestionEnum.CULTURA),
          planting: AnswerUtil.findValue(field.answers, QuestionEnum.PLANTIO),
          irrigatedArea: AnswerUtil.findValue(field.answers, QuestionEnum.AREA_IRRIGADA),
          area: field.area,
          group: AnswerUtil.findValue(field.answers, QuestionEnum.GRUPO),
          plantingDate: AnswerUtil.findValue(field.answers, QuestionEnum.DATA_PLANTIO),
          harvestDate: AnswerUtil.findValue(field.answers, QuestionEnum.DATA_COLHEITA)
        };
        this.$refs.mapa.adicionarLayer(field.geometry, field.name);
        field.leafletRef = `mapa_${field.id}`;
      }

      this.$refs.mapa.fitGroupBounds();
    },

    async getCityName(cityId) {
      const citySearched = this.citiesSearched[cityId];
      if (citySearched) {
        return citySearched;
      }
      const city = await CatalogService.getCity(cityId);
      this.citiesSearched[cityId] = city.name;
      return city.name;
    },
  }
};
</script>
<style lang='scss' scoped>
.resposta {
  display: flex;
  font-size: 0.83em;
  margin-block-start: 0.4em;
  margin-block-end: 0.4em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.pergunta {
  margin-left: 10px;
}

.tab-title {
  padding: 20px 0px 10px 0px;
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
}

.content-card {
  padding: 10px 20px 10px 20px;
  margin-bottom: 15px;
  min-height: 50px;
}

.format-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.subtitle {
  color: #999999;
  margin-right: 5px;
  font-size: 12px;
}

.value {
  color: #333333;
}

.lighter-value {
  color: #999;
}

.map {
  max-height: 400px;
}

.field-info-box {
  .format-text {
    margin-top: 15px;
  }
}
</style>
