<template lang="pug">
  div
    v-dialog(
      ref='dialog'
      v-if="isMobile"
      v-model='modal'
      :return-value.sync='date'
      width='90%'
    )
      template(v-slot:activator='{ on, attrs }')
        v-text-field.input(
          v-model='dateFormatted'
          v-on:input="onChange($event)"
          :label='label'
          v-on="on"
          append-icon='mdi-calendar'
          maxlength="10"
          :rules="rules"
          readonly
        )
      v-date-picker( v-model='date' scrollable='' @input="onChange($event)" locale="pt-BR" locale-first-day-of-year="pt-BR" :max='limitDate')

    v-menu(
      ref='menu'
      v-else
      v-model='menu'
      :close-on-content-click='false'
      :return-value.sync='date'
      transition='scale-transition'
      offset-y=''
      min-width='auto'
    )
      template(v-slot:activator='{ on, attrs }')
        v-text-field.input(
          v-model='dateFormatted'
          v-on:input="onChange($event)"
          :label='label'
          v-on="on"
          append-icon='mdi-calendar'
          maxlength="10"
          :rules="rules"
        )
      v-date-picker(v-model='date' no-title='' scrollable='' @input="onChange($event)" locale="pt-BR" locale-first-day-of-year="pt-BR" :max='limitDate')

</template>

<script>
import { isMobileDevice } from '@/utils/mobile.util';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: null,
    },
    limitDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMobile: false,
      menu: null,
      modal: null,
      date: null,
      dateFormatted: null,
    };
  },
  updated() {
    this.isMobile = isMobileDevice();
  },
  methods: {
    parseDate(date) {
      if (!date) { return null; }

      const [year, month, day] = date.split('-');
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    },
    onChange(event) {
      if (typeof event === 'string' && event.length < 11) {
        if (event.match(/^[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/)) {
          this.$emit('input', this.unparseDate(event));
          this.date = this.unparseDate(event);
        } else if (event.match(/^[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/)) {
          this.$emit('input', event);
          this.dateFormatted = this.parseDate(event);
        } else {
          this.dateFormatted = this.maskDate(event);
        }
      }

      this.menu = false;
      this.modal = false;
    },
    unparseDate(date) {
      if (!date) { return null; }

      const [day, month, year] = date.split('/');

      let dateString = '';
      if (year) dateString += `${year}-`;
      if (year && month) dateString += `${month.padStart(2, '0')}-`;
      if (year && month && day) dateString += `${day.padStart(2, '0')}`;

      return dateString;
    },
    maskDate(value) {
      const v = value.replace(/\D/g, '').slice(0, 10);
      if (v.length >= 5) {
        return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
      } if (v.length >= 3) {
        return `${v.slice(0, 2)}/${v.slice(2)}`;
      }

      return v;
    },
  },
};
</script>
<style lang="scss">
.input {
  input {
    cursor: pointer !important;
  }
}
</style>
