import ApiService from './api.service';

const route = '/services/process/api/events';

export default {
  save: async (event) => {
    const response = await ApiService.post(`${route}/${event.processCode}`, event);
    return response.data;
  },
  list: async (code) => {
    const response = await ApiService.get(`${route}/${code}`);
    return response.data;
  }
};
