import L from 'leaflet';
import 'leaflet-fullscreen';
import 'leaflet-editable';
import 'leaflet-draw';
import * as esri from 'esri-leaflet';
import GeoService from '@/services/geo.service';
import Wicket from 'wicket';

const Zoom = {
  MIN: 10,
  MIN_FULLSCREEN: 8,
  MIN_WINDOWED: 7,
};

export default class MapaService {
  constructor(mapa, code, options) {
    this.mapa = L.map(mapa, options);
    this.mapaTileLayer = esri.basemapLayer('Imagery').addTo(this.mapa);
    this.mapaTileLayerLabels = esri.basemapLayer('ImageryLabels').addTo(this.mapa);
    this.code = code;

    this.modoAtual = null;

    this.citiesGroup = L.featureGroup([]).addTo(this.mapa);
    this.citiesIconGroup = L.featureGroup([]).addTo(this.mapa);

    const self = this;
    this.mapa.on('fullscreenchange', () => {
      if (self.mapa.isFullscreen()) {
        self.mapa.setMinZoom(Zoom.MIN_FULLSCREEN);
      } else {
        self.mapa.setMinZoom(Zoom.MIN_WINDOWED);
      }
    });
  }

  getCity(layer) {
    const geom = new Wicket.Wkt();
    const wkt = geom.fromJson(layer.toGeoJSON()).write();

    return GeoService.getCloseCities(wkt);
  }

  fitBounds(bounds) {
    this.alreadyFitted = true;
    this.mapa.fitBounds(bounds);
  }

  invalidateSize() {
    this.mapa.invalidateSize();
  }

  fitCity(city) {
    const geom = L.geoJSON(city.geometry);
    this.mapa.fitBounds(geom.getBounds());
  }

  highlightCamada(camada, cor) {
    camada.setStyle({
      color: cor,
    });
  }

  adicionarGrupoCamadas(camadas = []) {
    return L.featureGroup(camadas).addTo(this.mapa);
  }

  removerGrupoCamadas(grupo) {
    this.mapa.removeLayer(grupo);
  }

  zoomGrupoCamadas(grupo) {
    this.mapa.fitBounds(grupo.getBounds());
  }

  adicionarControleZoom() {
    L.control
      .zoom({
        position: 'topright',
      })
      .addTo(this.mapa);
  }

  adicionarControleTelaCheia() {
    this.mapa.addControl(
      new L.Control.Fullscreen({
        title: {
          false: 'ligar tela cheia',
          true: 'Desligar tela cheia',
        },
        position: 'topright',
        pseudoFullscreen: false,
      }),
    );
  }

  changeTileTo(tileCode, tileLabels) {
    if (this.mapaTileLayer) {
      this.mapa.removeLayer(this.mapaTileLayer);
    }
    if (this.mapaTileLayerLabels) {
      this.mapa.removeLayer(this.mapaTileLayerLabels);
    }

    this.mapaTileLayer = esri.basemapLayer(tileCode).addTo(this.mapa);

    if (tileLabels) {
      this.mapaTileLayerLabels = esri.basemapLayer(tileLabels).addTo(this.mapa);
    }
  }
}
