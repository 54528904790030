const urls = {
  BASE_URL: process.env.VUE_APP_BASE_URL,
  BASE_URL_APP: process.env.VUE_APP_BASE_URL_APP,
  BASE_URL_ADMIN: process.env.VUE_APP_BASE_URL_ADMIN,
  BASE_URL_CONSULTANT: process.env.VUE_APP_BASE_URL_CONSULTANT,
  BASE_URL_REVIEWER: process.env.VUE_APP_BASE_URL_REVIEWER,
  BASE_URL_ANALYST: process.env.VUE_APP_BASE_URL_ANALYST,
};

export default urls;
