<template lang='pug'>
div
  include ./eventos.pug
</template>

<script>
import EventService from '@/services/event.service';
import FileManagementService from '@/services/fileManagement.service';

export default {
  data() {
    return {
      code: this.$route.params.id,
      events: [],
      images: [],
      foto: null,
      modalFoto: false,
      carregandoFoto: false,
      currentEventIdOpen: null
    };
  },
  async mounted() {
    this.events = await EventService.list(this.code);
  },
  methods: {
    async panelOpen(id) {
      if (this.currentEventIdOpen !== null && this.currentEventIdOpen === id) {
        this.currentEventIdOpen = null;
        return;
      }

      this.currentEventIdOpen = id;
      this.carregandoFoto = true;

      this.images = [];
      const event = this.events.find((item) => item.id === id);
      const idsImages = event.eventAttachments.map((item) => item.fileId);
      this.images = (await FileManagementService.downloadMany(idsImages)).map((f) => f.base64);
      this.carregandoFoto = false;
    },

    abrirFoto(item) {
      this.foto = item;
      this.modalFoto = true;
    },
  }
};
</script>
<style lang='scss' scoped>
.format-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.subtitle {
  color: #999999;
  margin-right: 5px;
  font-size: 12px;
}

.value {
  color: #333333;
}

.no-photo {
  display: flex;
  flex-direction: row;
  color: #333333;
  padding-bottom: 12px;
  font-weight: bold;
  font-size: 0.83em;
  padding: 10px;
}

.info-box {
  .format-text {
    margin-top: 15px;
  }
}

.v-expansion-panel {
  margin-bottom: 20px;
}

.icon-event {
  background-color: #ce1d1d;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  padding: 15px;
  margin-right: 5px;
}

#no-events {
  color: #999999;
  display: flex;
  justify-content: center;
}

.data-evento {
  font-size: 14px;
  margin-left: 5px;
}

.container-event {
  padding-top: 10px;
  font-size: 14px;
}

.card-foto {
  padding-top: 100px;
}
</style>
