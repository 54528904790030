import ApiService from './api.service';

export default {
  getProcess: async (code) => {
    const response = await ApiService.get('services/process/api/process', code);
    return response.data;
  },
  list: async (filter, pagerCount, pageSize) => {
    const response = await ApiService.post(`services/process/api/process/find/${pagerCount}/${pageSize}`, filter);
    return response.data;
  }
};
