<template lang='pug'>
  div#map(mapa ref='mapa')
    link(
      href='https://api.mapbox.com/mapbox.js/plugins/leaflet-fullscreen/v1.0.1/leaflet.fullscreen.css',
      rel='stylesheet'
    )
    div(container='left bottom')
      .side-icon(v-if='canCentralize')
        div(title='Centralizar', @click='centralize')
          span.mdi.mdi-target
    div(container='right bottom')
      .side-icon(:class='layersActive ? "active" : ""')
        div(@click='layersActive = !layersActive')
          span.mdi.mdi-layers
        .side-icon-box(
          v-show='layersActive',
          style='margin-top: -75px'
        )
          .available-layers
            v-radio-group(v-model='selectedLayer', column)
              v-radio(v-for='availableLayer in availableLayers', :key='availableLayer.code', border, :value='availableLayer.code', :label='availableLayer.name', @change='changeTileTo(availableLayer)')
    div(mapa)
</template>

<script>
import Wicket from 'wicket';
import GeoService from '@/services/geo.service';
import MapaService from './MapaService';

const southWest = L.latLng(-89.98155760646617, -180);
const northEast = L.latLng(89.99346179538875, 180);

export default {
  components: {},
  props: {
    code: {
      type: String,
      required: true,
    },
    field: {
      type: Object
    },
    canCentralize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layersActive: false,
      selectedLayer: 'Imagery',
      availableLayers: [
        {
          name: 'Terreno',
          code: 'Imagery',
          labels: 'ImageryLabels'
        },
        {
          name: 'Satélite',
          code: 'Topographic'
        }
      ],
      originalEditedLayerLatLngs: [],
      originalEditedLayerId: null,
      mapaService: null,
      configMapa: {
        maxBounds: L.latLngBounds(southWest, northEast),
        zoom: 15,
        minZoom: 7,
        maxZoom: 17,
        doubleClickZoom: false,
        zoomControl: false,
        attributionControl: true,
      },
      grupoCamadas: null,
      grupoTalhoes: [],
      styleCamada: {
        color: '#C6C7C8',
      },
    };
  },
  mounted() {
    const mapaRef = this.$refs.mapa;

    this.mapaService = new MapaService(mapaRef, this.code, {
      ...this.configMapa,
      ...(this.options || {}),
    });

    this.mapaService.adicionarControleZoom();
    this.mapaService.adicionarControleTelaCheia();

    const that = this;
    setTimeout(() => {
      that.mapaService.invalidateSize();

      that.grupoTalhoes = that.mapaService.adicionarGrupoCamadas();
      if (this.field) {
        this.adicionarLayer(this.field.geometry, this.field.name);
        this.fitGroupBounds();
      }
    });
  },
  methods: {
    centralize() {
      this.fitGroupBounds();
      this.mapaService.invalidateSize();
    },
    async fitBoundsOnCity(cityId) {
      const city = await GeoService.getCity(cityId);
      this.mapaService.fitCity(city);
    },

    fitGroupBounds() {
      if (this.grupoTalhoes.getLayers?.().length > 0) {
        this.mapaService.fitBounds(this.grupoTalhoes.getBounds());
      }
    },

    adicionarLayer(wkt, nome) {
      const geom = new Wicket.Wkt();
      geom.read(wkt);

      const latlng = [];
      for (const coords of geom.components[0]) {
        latlng.push([coords.y, coords.x]);
      }

      return this.adicionarLayerNoMapa(latlng, nome);
    },

    adicionarLayerNoMapa(coords, nome) {
      const polygon = L.polygon(coords);
      polygon.setStyle({
        color: '#C6C7C8',
        fillOpacity: 0.2
      });
      polygon.nome = nome;
      const area = this.getAreaAsText(L.GeometryUtil.geodesicArea(polygon.getLatLngs()[0]) / 10e3);
      polygon.bindPopup(`<p>Nome: ${nome}</p><p>Área (ha): ${area}</p>`, {
        autoClose: false,
        closeOnClick: false
      });

      this.grupoTalhoes.addLayer(polygon);
      return polygon._leaflet_id;
    },

    getAreaAsText(area) {
      return area.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    },

    highlightTalhao(id) {
      if (!id && !this.field) {
        return;
      }

      if (this.field) {
        this.fitGroupBounds();
      } else {
        for (const idLayer in this.grupoTalhoes._layers) {
          const layer = this.grupoTalhoes._layers[idLayer];
          if (idLayer === id.toString()) {
            this.mapaService.highlightCamada(layer, '#6F9ED0');
            this.mapaService.mapa.fitBounds(layer.getBounds());
          } else {
            this.mapaService.highlightCamada(layer, '#C6C7C8');
          }
        }
      }
    },

    changeTileTo(newTile) {
      this.mapaService.changeTileTo(newTile.code, newTile.labels);
    }
  }
};
</script>

<style lang="scss">
%mapa {
  width: 100%;
}

%container {
  position: absolute;
  z-index: 650;
}

.leaflet-control-zoom {
  a {
    color: rgba(0,0,0,.87)!important;
  }
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: none;
}

.side-icon {
  background-color: #fff;
  background: white;
  font-size: 20px;
  border-radius: 2px;
  line-height: 1;
  padding: 5px;
  cursor: pointer;
  max-height: 30px;
  div {
    display: inline-block;
  }

  &.active {
    background-color: #3ab985;
    color: #fff!important;
  }

  &:hover:not(.active) {
    background-color: #eee;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

[mapa] {
  @extend %mapa;
  .leaflet-control-attribution {
    display: none;
  }

  [container~="right"] {
    @extend %container;
    right: 10px;
    .side-icon-box {
      right: 30px;
    }
  }

  [container~="left"] {
    @extend %container;
    left: 10px;
    .side-icon-box {
      left: 30px;
    }
  }

  [container~="top"] {
    @extend %container;
    top: 10px;
  }

  [container~="bottom"] {
    @extend %container;
    bottom: 10px;
  }

  [container~="hide"] {
    max-height: 0;
  }
}

.side-icon-box {
  background-color: #fff;
  position: absolute;
  width: 110px;
  height: 100px;
  border-radius: 2px;
  padding: 5px;
}

.available-layers {
  color: #000;
  font-size: 15px;
  width: 100%;
  > .v-input {
    padding-top: 0!important;
  }
  .v-radio:not(:last-child) {
    margin-bottom: 15px!important;
  }
}
</style>
