<template lang="pug">
auth(title='Seja bem-vindo ao')
  template(v-slot:content)
    div(v-if='!passwordToken')
      include ./login.pug
    div(v-else)
      include ./password.pug
    v-dialog(
      v-model='addShortcut',
      transition='dialog-bottom-transition',
      width='530',
      content-class='auth-content-card'
    )
      v-card
        v-card-title.add-shortcut-card-title
            span Dicas para adicionar o Portal do Produtor
        v-card-text
          div(v-if='isAndroid')
            p
              | Para adicionar esta página à tela inicial de seu dispositivo:
              li Abra o menu de opções do navegador;
              li Selecione
                b &nbsp;Adicionar à tela inicial.
            p
              | O menu pode ser acessado pressionando o ícone&nbsp;
              i.mdi.mdi-dots-vertical
              | &nbsp;(ou similar) que geralmente fica no canto superior direito.
          div(v-else-if='isIOS')
            p
              | Para adicionar esta página aos seus favoritos:
            ul.add-shortcut-list
              li Abra o menu de opções do navegador;
              li Selecione
                b &nbsp;Adicionar Favoritos
                | .
            p
              | O menu pode ser acessado pressionando o ícone&nbsp;
              i.mdi.mdi-dots-vertical
              | ou&nbsp;
              i.mdi.mdi-book-open-blank-variant
              | &nbsp;ou similar.
          v-btn.btn(
            color='primary',
            @click='addShortcut = false'
          ) OK
</template>

<script>
import LoginService from '@/services/login.service';
import AccountService from '@/services/account.service';
import Roles from '@/constants/roles';
import { getToken, setToken, removeToken } from '@/utils/security.util';
import { normalize } from '@/utils/string.util';
import ApiService from '@/services/api.service';
import config from '@/constants/config';
import { isDeviceAndroid, isDeviceIOS } from '@/utils/mobile.util';
import Auth from './Auth.vue';

const CPF_MASK = '###.###.###-##';
const CNPJ_MASK = '##.###.###/####-##';

export default {
  name: 'Login',
  components: {
    Auth
  },
  data() {
    return {
      addShortcut: false,
      passwordToken: false,
      newPassword: null,
      confirmPassword: null,
      snackbar: true,
      valid: false,
      cpfCnpj: null,
      password: null,
      showPasswd: false,
      showPasswordInfo: false,
      mask: CPF_MASK,
      isAndroid: isDeviceAndroid(),
      isIOS: isDeviceIOS(),
      rules: {
        required: (value) => !!value || 'Campo obrigatório.',
        login: {
          cpfCnpj: (value) => {
            const pattern = /^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/;
            return pattern.test(value) || (value?.length < 15 ? 'CPF inválido.' : 'CNPJ inválido.');
          },
        },
        password: {
          chars: (value) => {
            if (value.length < 8) {
              return 'Senha inválida.';
            }
            const normalized = normalize(value);
            if (!/[A-Z]/.test(normalized) || !/[a-z]/.test(normalized) || !/\d/.test(normalized) || !/[()!@#$%&/^"*_\-+=§`'{}[\]ª~º°<>,.;:?|\\]/.test(normalized)) {
              return 'Senha inválida.';
            }
            return true;
          },
          matches: (value) => {
            if (value !== this.newPassword) {
              return 'Senhas não conferem.';
            }
            return true;
          }
        }
      },
    };
  },
  async mounted() {
    this.passwordToken = this.$route.params.passwordToken;
    const token = getToken();
    if (token) {
      if (!this.passwordToken) {
        this.$router.push({ name: 'Home' });
        return;
      }
      removeToken();
    }

    if (this.passwordToken) {
      try {
        await AccountService.validateToken(this.passwordToken);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: e.message || e.title,
        });
        this.back();
      }
    }
  },
  methods: {
    maskValue() {
      if (this.cpfCnpj && this.cpfCnpj.replace(/[^\w\s]/gi, '').length > 11) {
        this.mask = CNPJ_MASK;
        return;
      }

      this.mask = CPF_MASK;
    },

    async createNewPassword() {
      const valid = this.$refs.formNewPassword.validate();
      if (!valid) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: 'Verifique o preenchimento dos campos.',
        });
        return;
      }
      try {
        await AccountService.newPassword(this.passwordToken, { password: this.newPassword });
        this.$notify({
          type: 'success',
          title: 'Sucesso',
          text: 'Senha criada com sucesso.',
        });
        this.back();
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: e.message || 'Problema ao cadastrar nova senha.',
        });
      }
    },
    back() {
      this.passwordToken = false;
      this.$router.push({ name: 'Login' });
      this.$nextTick(() => {
        this.$refs.formLogin.reset();
      });
    },
    async doLogin() {
      const valid = this.$refs.formLogin.validate();
      if (!valid) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: 'Verifique o preenchimento dos campos.',
        });
        return;
      }

      try {
        const loginObject = {
          cpfCnpj: this.cpfCnpj.replace(/[^\w\s]/gi, ''),
          password: this.password,
          profile: { name: Roles.RURAL_PRODUCER },
        };

        const result = await LoginService.login(loginObject);
        if (result.idToken === null || result.profile.name !== Roles.RURAL_PRODUCER) {
          this.$notify({
            type: 'error',
            title: 'Erro',
            text: 'Problema ao autenticar.',
          });
          return;
        }

        this.$notify({
          type: 'success',
          title: 'Sucesso',
          text: 'Usuário logado.',
        });
        setToken(result.idToken);
        ApiService.setHeader();
        const { redirect } = this.$route.query;
        if (redirect) {
          window.location.href = `${config.BASE_URL}/${redirect}`;
        } else {
          this.$router.push({ name: 'Home' });
        }
      } catch (error) {
        if (error.message.includes('was not activated')) {
          this.$notify({
            type: 'error',
            title: 'Erro',
            text: 'Usuário inativo, entre em contato com um Gestor do sistema.',
          });
        } else {
          this.$notify({
            type: 'error',
            title: 'Erro',
            text: 'Verifique seu usuário e sua senha.',
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.add-shortcut {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  font-size: 13px;
  cursor: pointer;
}

.auth-content-card {
  margin: 12px !important;
  text-align: left;
  .content-card {
    padding: 10px 20px 10px 20px;
    min-height: 50px;
    font-size: 15px;
  }
}
.add-shortcut-link {
  text-decoration: underline;
  padding-left: 5px;
}
.add-shortcut-card-title {
  display: flex;
  justify-content: center;
  flex-flow: row;
  padding: 5px!important;
  word-break: break-word;
  span {
    font-size: 14px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 6px;
    border-radius: 10px;
    width: 94%;
    background-color: #e6e6e6;
    text-align: center;
  }
}

.add-shortcut-list {
  text-align: left;
  margin-top: -10px;
}

.btn {
  display: flex !important;
  margin: 0 auto;
}
</style>
