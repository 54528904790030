import ApiService from './api.service';

const route = 'services/management/api/channel';

const ChannelService = {
  get: async (id) => {
    const response = await ApiService.get(`${route}/${id}`);
    return response.data;
  },
};

export default ChannelService;
