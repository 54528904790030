<template lang='pug'>
div
  include ./documentos.pug
</template>

<script>
import FileManagementService from '@/services/fileManagement.service';
import AttachmentService from '@/services/attachment.service';

export default {
  data() {
    return {
      code: this.$route.params.id,
      documents: []
    };
  },
  async mounted() {
    this.documents = await AttachmentService.getByProcess(this.code);
  },
  methods: {
    async generatePDF(fileId) {
      await FileManagementService.download(fileId);
    },
    async generateAllPDF() {
      await this.documents.forEach(async (x) => {
        await FileManagementService.download(x.fileId);
      });
    },
  }
};
</script>
<style lang='scss' scoped>
.card-doc {
  margin-bottom: 14px;
  padding: 10px;
}

.title-info-doc {
  font-size: 12px;
  color: #999999;
  margin-right: 5px;
}

.subtitle-info-doc {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
}

.content-card-doc {
  display: grid;
  grid-template-columns: 50px 1fr 30px;
  grid-template-areas: "icon guia icon-download";
  flex-direction: row;
  margin-bottom: 10px;
}

.guia-doc {
  display: flex;
  align-items: center;
  text-align: start;
}

.info-cpr-doc {
  display: flex;
  flex-direction: column;
}

.icon-download-doc {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.icon {
  background-color: #77c43e;
  height: 40px;
  width: 40px;
  border-radius: 100px;
}

#no-events {
  color: #999999;
  display: flex;
  justify-content: center;
}

a {
  color: #02B4DC !important;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  margin: 24px 0px 0px 0px;
}

@media screen and (max-width: 474px) {
  .subtitle-info-doc {
    max-width: 220px;
  }
}

@media screen and (max-width: 375px) {
  .subtitle-info-doc {
    max-width: 178px;
  }
}

@media screen and (max-width: 320px) {
  .subtitle-info-doc {
    max-width: 150px;
  }
}

@media screen and (max-width: 298px) {
  .content-card-doc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
