<template lang="pug">
  #main
    v-container.menu
      h3#title Créditos
      .items
        h4.item(@click='filterDate') Data de emissão
          v-icon#filterDateAsc(v-if='filterDateAsc') mdi-chevron-up
          v-icon#filterDateDesc(v-else) mdi-chevron-down
    v-container
      span.title-info#noProcess(v-if='!listProcess.length') Sem processos para exibir
      v-card(v-else v-for='(process, index) in listProcess' :key='index' @click='sendCpr(process)' :id="`cardCpr${index}`")
        .content-top.content-top
          v-icon.icon(color='#FFFFFF', small, :class='process.cancelledByEndorsement ? "red-icon" : ""')
            | mdi-file-document
          .quota
            .info-cpr
              .title-info Cotação
                .quota-endorsement(v-if='process.cancelledByEndorsement') (Esta cotação foi cancelada)
              .subtitle-info#codeCpr {{ process.code }}
          .area
            .info-cpr
              .title-info Área (ha)
              .subtitle-info#totalAreaCpr {{ process.result.totalArea | decimal }}
          v-icon.next-icon(color='#77C43E') mdi-arrow-right
        v-divider
        .content-bottom
          .emission
            .title-info Emissão
            .subtitle-info(:id="`emissionDateCpr${index}`") {{ process.emissionDate | dateTime }}
          .channel
            .title-info Canal
            .subtitle-info#channelCpr {{ process.channelName }}
      v-pagination#pagination(
        v-if='listProcess.length > 0'
        v-model='currentPage',
        :length='pagination.totalPages',
        :total-visible='7',
        @input='setPage',
        @next='filter(1)',
        @previous='filter(-1)'
      )
</template>

<script>
import ProcessService from '@/services/process.service';

export default {
  data() {
    return {
      listProcess: [],
      channel: [],
      filterDateAsc: false,
      currentPage: 1,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalElements: 0,
        totalPages: 1
      }
    };
  },
  mounted() {
    this.filter();
  },
  methods: {
    sendCpr(process) {
      this.$router.push({ name: 'VisualizarCotacao', params: { id: process.code } });
    },

    setPage(page) {
      this.pagination.pageNumber = page;
      this.filter();
    },
    async filter(page = null) {
      if (page !== null) {
        this.pagination.pageNumber += page;
      }
      const paginacao = await ProcessService.list({
        dataEmissaoAsc: this.filterDateAsc
      }, this.pagination.pageNumber, this.pagination.pageSize);
      this.pagination.totalElements = paginacao.data.totalElements;
      this.pagination.totalPages = paginacao.data.totalPages;
      this.listProcess = paginacao.data.content;
    },
    filterDate() {
      this.filterDateAsc = !this.filterDateAsc;
      this.filter();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../variables.scss";
#main {
  background-color: #f8fafb;
}
.menu {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-template-areas: "cpr text";
  flex-direction: row;
  align-items: center;
}
.container.menu {
    padding: 20px 20px 20px 20px !important;
}
#title {
  color: #398097;
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
}
.items {
  display: flex;
  color: #666666;
  font-size: 13px;
  align-items: center;
  justify-content: flex-end;
}
.item {
  display: flex;
  align-items: center;
}
.container {
  padding-top: 0px;
  padding-bottom: 0px;
}
.v-card {
  margin-bottom: 14px;
  padding: 10px;
}
.title-info {
  font-size: 12px;
  color: #999999;
  margin-right: 5px;
}
.subtitle-info {
  font-size: 12px;
  font-weight: 700;
  color: #333333;
  text-align: left;
}
.content-top {
  display: grid;
  grid-template-columns: 50px 1fr 1fr 30px;
  grid-template-areas: "icon quota area next-icon";
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}
.next-icon {
  display: flex;
  justify-content: flex-end;
}
.icon {
  background-color: #77c43e;
  height: 40px;
  width: 40px;
  border-radius: 100px;
  margin-right: 16px;
}
.red-icon {
  background-color: $--error;
}
.quota {
  display: flex;
  align-items: center;
}
.info-cpr {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.content-bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "emission channel";
  align-items: center;
  margin-top: 4px;
  align-items: flex-start;
  padding-top: 4px;
}
.emission {
  display: flex;
  justify-content: flex-start;
}
.channel {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 414px) {
  .area {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 312px) {
  .content-top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .content-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.quota-endorsement {
  color: $--error;
  font-size: 10px;
}

</style>
