export default class AnswerUtils {
  static findAnswer(list, questionId) {
    return list.find((anwser) => anwser.questionId === questionId || anwser.question?.id === questionId);
  }

  static findValue(list, questionId) {
    return AnswerUtils.findAnswer(list, questionId)?.value;
  }

  static findAlternative(list, questionId) {
    const answer = AnswerUtils.findAnswer(list, questionId);
    if (!answer) {
      return null;
    }
    return answer.alternative?.id ?? answer.alternativeId;
  }
}
