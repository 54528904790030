import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { getToken, removeToken } from '@/utils/security.util';
import appConfig from '@/constants/config';
import Bus from '@/utils/event-bus.util';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);

    Vue.axios.defaults.baseURL = process.env.VUE_APP_GATEWAY_MICROSERVICE;
    Vue.axios.defaults.timeout = 300000;

    axios.interceptors.request.use((request) => {
      if (!request.hideLoading) {
        Bus.$emit('loading:increase');
      }
      return request;
    }, (error) => {
      Bus.$emit('loading:reset');
      return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
      Bus.$emit('loading:decrease');
      return response;
    }, (error) => {
      Bus.$emit('loading:reset');
      return Promise.reject(error);
    });

    this.setHeader();
  },

  setHeader() {
    const jwt = getToken();
    if (jwt) {
      Vue.axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    }
    Vue.axios.defaults.headers.post['Content-Type'] = 'application/json';
    Vue.axios.defaults.headers.put['Content-Type'] = 'application/json';
  },

  async forceDownload(resource) {
    const fileLink = document.createElement('a');
    try {
      const response = await Vue.axios.get(resource, { responseType: 'blob' });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      fileLink.href = fileURL;

      const filenameRegex = /filename=['"]?([^;\r\n"']*)['"]?;?/;
      const contentDisposition = response.headers['content-disposition'];

      fileLink.setAttribute('download', filenameRegex.exec(contentDisposition)[1]);
      document.body.appendChild(fileLink);

      fileLink.click();
      return Promise.resolve();
    } catch (error) {
      const result = this.genericErrorHandling(error);

      return Promise.reject(result);
    } finally {
      fileLink.remove();
    }
  },

  async query(resource, params) {
    try {
      return await Vue.axios.get(resource, { params });
    } catch (error) {
      const result = this.genericErrorHandling(error);

      return Promise.reject(result);
    }
  },

  async get(resource, slug = '') {
    try {
      if (slug) {
        return await Vue.axios.get(`${resource}/${slug}`);
      }
      return await Vue.axios.get(resource);
    } catch (error) {
      const result = this.genericErrorHandling(error);

      return Promise.reject(result);
    }
  },

  async post(resource, params, config) {
    try {
      return await Vue.axios.post(resource, params, config);
    } catch (error) {
      const result = this.genericErrorHandling(error);

      return Promise.reject(result);
    }
  },

  async update(resource, slug, params) {
    try {
      return await Vue.axios.put(`${resource}/${slug}`, params);
    } catch (error) {
      const result = this.genericErrorHandling(error);

      return Promise.reject(result);
    }
  },

  async put(resource, params) {
    try {
      return await Vue.axios.put(resource, params);
    } catch (error) {
      const result = this.genericErrorHandling(error);

      return Promise.reject(result);
    }
  },

  async delete(resource) {
    try {
      return await Vue.axios.delete(resource);
    } catch (error) {
      const result = this.genericErrorHandling(error);

      return Promise.reject(result);
    }
  },

  genericErrorHandling(error) {
    if (error.response.status === 403) {
      removeToken();
      window.location.href = appConfig.BASE_URL;
      return {};
    }

    const data = error.response?.data ?? error;

    return data;
  },
};

export default ApiService;
