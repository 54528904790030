<template lang="pug">
#auth
  #header
    .logo Smart Credit
  #content
    #main-content
      .title-message
        .sub-title {{title}}
        .auth-title Portal do Produtor
      slot(name='content')
</template>

<script>
export default {
  name: 'Auth',
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
@import "../../variables.scss";

#auth {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $--calypso;

  #header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $--shakespeare;
    height: 75px !important;

    .logo {
      color: #fff;
      font-size: 30px;
    }
  }

  #content {
    display: flex;
    justify-content: center;
    align-items: center;

    #main-content {
      margin: 30px 50px 0 50px;
      width: 50%;
      color: white;

      .title-message {
        flex-direction: column;
        text-align: left;

        .sub-title {
          font-size: 15px;
        }
        .auth-title {
          font-size: 20px;
          color: white;
          font-weight: bold;
        }
      }

      #form {
        margin-top: 30px;
      }

      .submit-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        a {
          font-size: 13px;
          text-decoration: underline;
          cursor: pointer;
          color: white !important;
        }

        .v-btn {
          height: 48px!important;
          font-size: 16px!important;
          text-transform: none;
        }
      }

      .password-info {
        font-size: 11px;
        text-align: left;
        margin-top: 10px;

        ul {
          padding: 5px;
          list-style-type: none;
        }
        ul > li {
          text-indent: -5px;
        }
        ul > li:before {
          content: "- ";
          text-indent: -5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #auth {
    #content {
      #main-content {
        width: 100%;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: white !important;
}
</style>
