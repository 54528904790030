<template lang="pug">
  #portal-produtor
    v-overlay.loading-overlay(v-if='counter > 0')
      .loading
        v-progress-circular(
          indeterminate,
          color='red'
        )
    notifications(classes='notification')
    v-app
      Navbar
      router-view
      v-container(v-if='!hideNavbar')
        .cpr-info
          span Em caso de dúvidas ou reclamações entrar em contato com o Credor do seu crédito.
</template>

<script>
import Bus from '@/utils/event-bus.util';
import Navbar from '@/components/Navbar.vue';

export default {
  components: {
    Navbar
  },
  data() {
    return {
      counter: 0
    };
  },
  computed: {
    hideNavbar() {
      return this.$route.meta.hideNavbar;
    }
  },
  created() {
    Bus.$on('loading:increase', () => {
      this.counter += 1;
    });
    Bus.$on('loading:decrease', () => {
      if (this.counter > 0) {
        this.counter -= 1;
      } else {
        this.counter = 0;
      }
    });
    Bus.$on('loading:reset', () => {
      this.counter = 0;
    });
  }
};
</script>

<style lang="scss">
@import "./variables.scss";

html {
  overflow: initial;
}

html, body, #app, #portal-produtor {
  height: 100%!important;
  background-color: #f8fafb !important;
}

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
}

.vue-notification-group {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-overlay {
  z-index: 100000!important;
  .loading {
    position: absolute!important;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    > div {
      top: 50%;
      > svg {
        color: $--calypso;
      }
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.notification {
  padding: 10px;
  margin: 10px;

  font-size: 12px;

  color: #000;
  background-color: #ffffff !important;
  border-left: 5px solid #187FE7;

  &.error {
    border-left-color: $--error !important;
  }

  &.success {
    border-left-color: $--success !important;
  }
}

@media only screen and (max-width: 768px) {
  .sub-header {
    height: 30px;
  }
}

.cpr-info {
  float: left;
  span {
    color: #666;
    font-weight: bold;
    font-style: italic;
    font-size: 14px;
  }
}
</style>
