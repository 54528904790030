const Roles = {
  SYS_ADMIN: 'ROLE_SYS_ADMIN',
  CHANNEL_ADMIN: 'ROLE_CHANNEL_ADMIN',
  CHANNEL_REVIEWER: 'ROLE_CHANNEL_REVIEWER',
  CHANNEL_CONSULTANT: 'ROLE_CHANNEL_CONSULTANT',
  INSURER_ADMIN: 'ROLE_INSURER_ADMIN',
  INSURER_ANALYST: 'ROLE_INSURER_ANALYST',
  MUNICH_RE_CONSULTANT: 'ROLE_MUNICH_RE_CONSULTANT',
  RURAL_PRODUCER: 'ROLE_RURAL_PRODUCER',
};

export default Roles;
