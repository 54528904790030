<template lang="pug">
  div(v-if="!hideNavbar")
    .header
      v-container
        .logo
          router-link(to='/cotacoes') Smart Credit
    .sub-header
      v-container.nav
        .text
          router-link(to='/cotacoes') Portal do Produtor
        #btn-logout.mdi.mdi-logout(@click='logout')
          span#btn-logout Sair
</template>

<script>
import config from '@/constants/config';
import { removeToken } from '@/utils/security.util';

export default {
  computed: {
    hideNavbar() {
      return this.$route.meta.hideLayout || this.$route.meta.hideNavbar;
    },
  },
  methods: {
    logout() {
      removeToken();
      window.location.href = config.BASE_URL;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../variables.scss";

.sub-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #398097;
  color: white;
  height: 60px;
}
.header {
  display: flex;
  align-items: center;
  background-color: $--shakespeare;
  height: 40px;
}
.container {
  display: flex;
  justify-content: flex-start;
}
.logo {
  a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
  }
}

.text {
  a {
    color: #fff;
    text-decoration: none;
  }
}

.container.nav {
  display: grid;
  grid-template-columns: 1fr 46px;
  grid-template-areas: "text btn-logout";
  color: #ffffff;
}
.text {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  align-items: center;
}
#btn-logout {
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  cursor: pointer;
}
</style>
