<template lang="pug">
.about
  v-container
    .title-container
      h1.new-event-title Comunicar novo evento
      v-icon.new-event-title(@click="cancelRegister") mdi-close

    v-form#form(:model="valid" ref="form")
      v-row
        v-col(cols="12" md="6")
          v-text-field#responsibleName(
            counter='50'
            :maxlength='50'
            v-model="event.responsibleName"
            label="Responsável"
            color="#333333"
            placeholder="Nome completo"
            :rules="[eventRules.required]"
          )
        v-col(cols="12" md="6")
          v-text-field#processCode(
            v-model="event.processCode"
            label="Crédito afetado"
            color="#333333"
            disabled
            :rules="[eventRules.required]"
          )

      v-row
        v-col(cols="12" md="6")
          v-textarea#descriptionEvent(
            counter='500'
            :maxlength='500'
            v-model="event.description"
            label="Descrição do evento ocorrido"
            placeholder="Descreva em detalhes"
            color="#333333"
            no-resize
            :rules="[eventRules.required]"
          )
        v-col(cols="12" md="6")
          datepicker#ocurrenceDate(v-model="event.occurrenceDate" label="Data da ocorrência"
            :rules="[eventRules.required, eventRules.occurrenceDateValid]"
            :limitDate="nowDate")
          datepicker#cropStartDate(v-model="event.cropStartDate" label="Data estimada de início da colheita"
            :rules="[eventRules.required, eventRules.cropStartDateValid]")

      v-row
        v-col(cols="12" md="6")
          v-text-field#surveyContactName(
            counter='50'
            :maxlength='50'
            v-model="event.surveyContactName"
            label="Nome do contato para vistoria"
            color="#333333"
            placeholder="Nome completo"
            :rules="[eventRules.required]"
          )
        v-col(cols="12" md="6")
          v-text-field#surveyContactPhone(
            v-model="event.surveyContactPhone"
            label="Telefone do contato para vistoria"
            color="#333333"
            placeholder="(##) #####-####"
            v-mask="phoneMask"
            type="tel"
            :rules="[eventRules.required, eventRules.surveyContactPhoneValid]"
          )

      v-dialog(v-model="affectedAreaDialog" @click:outside="cancelFieldEditing" width='90%' persistent)
        template(v-slot:activator="{ }")
          div(style="margin-bottom: 25px")
            span.field-label Marque os talhões afetados pelo evento
            div(v-for="(field, index) in fields" :key="field.id")
              v-card.card(:class="field.checked && 'card-checked'")
                .checkbox-container#checkbox(:class="field.checked && 'checkbox-container-checked'")
                  v-checkbox(v-model="field.checked" color="white" @change="toggleFieldInput($event, field)")
                .card-content
                  v-row(justify="space-around")
                    v-col.field-id(cols="4" md="3")
                      span.label Identificação
                      span.value#nameField {{field.name}}
                    v-col.area(cols="4" md="3")
                        span.label Área do talhão (ha)
                        span.value#areaField {{ field.area | decimal }}
                    v-col(cols="3" md="4" style="display: flex; align-items:center")
                      .area(v-if="isMobile")
                        span.label Área (ha)
                        span.value#affectedArea(:class="field.affectedArea === 0 && 'value-disabled'")
                          | {{field.affectedArea !== null && field.affectedArea !== undefined ? field.affectedArea : '-'}}
                      .input(v-else)
                        v-text-field(
                          :id="`affectedAreaInput${index}`"
                          :disabled="!field.checked"
                          label="Área (ha) afetada pelo evento"
                          v-model="field.affectedArea"
                          color="#333333"
                          type="number"
                          min="0"
                          :max="field.area"
                          step=".01"
                          @keyup="fieldAffectedAreaInputOnChange(field)"
                          @blur="fieldAffectedAreaInputOnChange(field)"
                          :rules="!field.affectedArea && (editingAffectedArea && field.checked) ? [affectedAreaRules.required, affectedAreaRules.value] : []"
                        )
        template(v-slot:default="dialog")
          .dialog
            v-form(:model="affectedAreaValid" ref="affectedAreaForm")
              span Qual a área (ha) afetada pelo evento?
              v-text-field#affectedAreaInput(
                v-if="editedField"
                v-model="editedField.affectedArea"
                color="#333333"
                type="number"
                min="0"
                :max="editedField.area"
                step=".01"
                @keyup="fieldAffectedAreaInputOnChange(editedField)"
                @blur="fieldAffectedAreaInputOnChange(editedField)"
                :rules="[affectedAreaRules.required, affectedAreaRules.value]"
              )
            v-btn#buttonConfirm(
              elevation="0"
              dark
              color="#1D4F7F"
              style="width: 50%; text-transform: none; margin-top: 15px"
              @click="finishFieldEditing"
            )
              v-icon(size="14" style="margin-right: 5px") mdi-check
              span Confirmar

      v-file-input(
        v-model='fileAux',
        ref='refInput',
        accept='image/*',
        multiple,
        :prepend-icon='""',
        :hide-input='true',
        @change='addFiles'
      )
        template(slot="selection")

      span.field-label Fotografias ({{event.files.length}}/10 em formato JPG, JPEG ou PNG)
      v-btn#photo-btn(
        :disabled='event.files.length > 9'
        elevation="0"
        outlined
        color="#1D4F7F"
        @click="openFileSelection"
      )
        v-icon(size="14") mdi-camera
        span Enviar fotos
      .photo-attachment-container
        v-chip(
          v-for="(file, index) in event.files"
          :key="file.id"
          close close-icon="mdi-close"
          @click:close="removeFile(index)"
        )
          span {{file.originalFileName}}

      v-btn#finish-btn(
        elevation="0"
        dark
        color="#77C43E"
        @click="submitEvent"
      )
        v-icon(size="14") mdi-check
        span Concluir comunicação do evento
</template>

<script>
import MapaUtil from '@/utils/mapa.util';
import EventService from '@/services/event.service';
import FieldService from '@/services/field.service';
import FileManagementService from '@/services/fileManagement.service';
import Datepicker from '@/components/Datepicker.vue';
import Validators from '@/utils/validators';
import { isMobileDevice } from '@/utils/mobile.util';

const PHONE_MASK = '(##) ####-####';
const CELPHONE_MASK = '(##) #####-####';

export default {
  name: 'EventForm',
  components: {
    Datepicker,
  },
  data() {
    return {
      fileAux: {},
      nowDate: new Date().toISOString().slice(0, 10),
      isMobile: false,
      valid: false,
      affectedAreaValid: false,
      affectedAreaDialog: false,
      editingAffectedArea: false,
      phoneMask: PHONE_MASK,
      event: {
        responsibleName: '',
        processCode: '',
        occurrenceDate: '',
        cropStartDate: '',
        surveyContactName: '',
        surveyContactPhone: '',
        files: [],
      },
      eventRules: {
        required: (value) => !!value || 'Campo obrigatório.',
        occurrenceDateValid: (value) => (Validators.isValidDate(value) && Validators.dateIsNotAfterToday(value)) || 'Data inválida.',
        cropStartDateValid: (value) => Validators.isValidDate(value) || 'Data inválida.',
        surveyContactPhoneValid: (value) => {
          const pattern = /^(\(\d{2}\)\s)?(\d{4,5}-\d{4})$/;
          return pattern.test(value) || 'Telefone inválido.';
        },
      },
      affectedAreaRules: {
        required: (value) => (value !== null && value !== undefined) || 'Campo obrigatório.',
        value: (value) => value > 0 || 'O valor deve ser maior que 0.',
      },
      fields: [],
      editedField: null,
      fileInput: null
    };
  },
  async mounted() {
    this.isMobile = isMobileDevice();

    this.event.processCode = this.$route.params.id;
    this.fields = await FieldService.list(this.event.processCode);
    this.fields = this.fields.map((field) => {
      field.area = MapaUtil.getGeoJsonArea(field.geometry);
      return field;
    });
    window.localStorage.setItem('eventRegistry', '1');

    this.fileInput = this.$refs.refInput.$refs.input;
  },

  watch: {
    // eslint-disable-next-line func-names
    'event.surveyContactPhone': function () {
      if (this.event.surveyContactPhone.length > 14) {
        this.phoneMask = CELPHONE_MASK;
        return;
      }

      this.phoneMask = PHONE_MASK;
    },
  },
  methods: {
    fieldAffectedAreaInputOnChange(field) {
      if (parseFloat(field.affectedArea) > field.area) {
        field.affectedArea = field.area;
      }
    },
    toggleFieldInput(event, field) {
      if (!event) {
        field.affectedArea = undefined;
        this.editingAffectedArea = false;
        return;
      }

      if (this.isMobile) this.affectedAreaDialog = true;
      this.editingAffectedArea = true;
      this.editedField = { ...field };
    },
    cancelFieldEditing() {
      const originalField = this.fields.find((f) => f.id === this.editedField.id);
      originalField.checked = false;
      this.editedField = null;
      this.editingAffectedArea = false;
      this.affectedAreaDialog = false;
    },
    finishFieldEditing() {
      const valid = this.$refs.affectedAreaForm.validate();
      if (!valid) return;

      const originalField = this.fields.find((f) => f.id === this.editedField.id);
      if (originalField) {
        originalField.affectedArea = this.editedField.affectedArea;
        this.editedField = null;
      }

      this.editingAffectedArea = false;
      this.affectedAreaDialog = false;
    },
    async removeFile(fileIndex) {
      const file = this.event.files[fileIndex];
      if (file) {
        await FileManagementService.delete(file.id);
        this.event.files.splice(fileIndex, 1);
        this.$notify({
          type: 'success',
          title: 'Sucesso',
          text: 'Arquivo removido com sucesso.',
        });
      }
    },
    async addFiles(addedFiles) {
      this.fileInput.value = '';
      if (this.event.files.length + addedFiles.length > 10) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: 'Não é possível adicionar mais de 10 arquivos.',
        });
        return;
      }
      const isSeveralFiles = addedFiles.length > 1;

      const promises = [];
      addedFiles.forEach((file) => {
        const uploadPromise = FileManagementService.upload(file, this.event.processCode);
        promises.push(uploadPromise);
      });

      try {
        const response = await Promise.all(promises);
        this.event.files.push(...response);
        this.$notify({
          type: 'success',
          title: 'Sucesso',
          text: `${isSeveralFiles ? 'Arquivos adicionados' : 'Arquivo adicionado'} com sucesso.`,
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: e.message || `Problema ao enviar ${isSeveralFiles ? 'um' : 'o'} arquivo.`,
        });
      }
    },
    validateFieldAffectedArea() {
      return this.fields.some((f) => f.checked && !!f.affectedArea);
    },
    async submitEvent() {
      const valid = this.$refs.form.validate();
      const fieldsValid = this.validateFieldAffectedArea();
      if (!valid || !fieldsValid) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: 'Verifique o preenchimento dos campos.',
        });
        return;
      }

      try {
        this.event.filesIds = this.event.files.map((f) => f.id);
        await EventService.save({
          ...this.event,
          occurrenceDate: new Date(this.event.occurrenceDate).toJSON(),
          cropStartDate: new Date(this.event.cropStartDate).toJSON(),
          fieldAreaAffectedByEvent: this.fields.filter((f) => f.checked).map((field) => ({
            fieldId: field.id,
            area: field.affectedArea,
          })),
        });
        this.$router.push({ name: 'VisualizarCotacao', params: { id: this.event.processCode } });
        this.$notify({
          type: 'success',
          title: 'Sucesso',
          text: 'Evento comunicado com sucesso.',
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: 'Houve um problema ao salvar o evento.',
        });
      }
    },
    openFileSelection() {
      this.fileInput.click();
    },
    async cancelRegister() {
      const promises = [];
      this.event.files.forEach((file) => {
        const uploadPromise = FileManagementService.delete(file.id);
        promises.push(uploadPromise);
      });

      try {
        await Promise.all(promises);
        this.$router.push({ name: 'VisualizarCotacao', params: { id: this.event.processCode } });
      // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }
};
</script>

<style lang="scss">
@import '../variables.scss';
.about {
  padding: 15px;
}
.title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.new-event-title {
  font-size: 20px;
  font-weight: bold;
  color: $--bluemine !important;
}
.v-dialog { margin: 0; }

#photo-btn {
  text-transform: none;
  width: 100%;

  .v-btn__content {
    i {
      margin-right: 5px;
    }
    justify-content: center;
    align-items: center;
  }
}

#finish-btn {
  text-transform: none;
  font-weight: bold;
  margin-top: 15px;
  width: 100%;
  height: 50px !important;
  .v-btn__content {
    i {
      margin-right: 5px;
    }
  }
}

.card {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;

  .checkbox-container {
    flex: 0.15;
  }
  .card-content {
    flex: 0.85;
    display: flex;
    justify-content: space-between;
  }
}

.card-checked {
  border: 1px solid $--sushi !important;
}

.checkbox-container-checked {
  background-color: $--sushi !important;
}

.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $--gallery;
  border-radius: 0px !important;

  .v-input--selection-controls__input {
    margin: 0 !important;
  }
}

.card-content {
  .visible {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
  }

  .input {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    button {
      text-transform: none;
      margin-left: 10px;
      min-width: 0 !important;
      padding: 0 10px !important;
    }

    .v-btn__content {
      i {
        margin-right: 0 !important;
      }
    }
  }
}

.field-id, .area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  span {
    line-height: 12px;
  }
  .label {
    font-size: 11px;
    color: $--dusty-gray;
  }
  .value {
    font-size: 13px;
    color: #000;
    font-weight: bold;
  }
}

.value-disabled {
  color: $--dusty-gray !important;
}

.dialog {
  padding: 10px;
  background-color: white;
}

.field-label {
  display: flex;
  text-align: left;
  font-size: 13px;
  color: rgba(0,0,0,.6);
}

.photo-attachment-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  > span {
    margin: 5px 5px 5px 0;
  }
}

@media only screen and (max-width: 768px) {
  .area {
    span {
      line-height: 12px;
    }
    .label {
      font-size: 10px;
      color: $--dusty-gray;
    }
    .value {
      font-size: 12px;
      color: #000;
      font-weight: bold;
    }
  }
}
</style>
