import ApiService from './api.service';

const route = '/services/filemanagement/api/file';

export default {
  upload: async (file, processCode) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('processCode', processCode);
    formData.append('fileTypeCode', 'ANEXO_EVENTO');

    const headerConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    };

    const response = await ApiService.post(`${route}/upload`, formData, headerConfig);
    return response.data;
  },
  delete: async (fileId) => {
    const response = await ApiService.delete(`${route}/${fileId}`);
    return response.data;
  },
  downloadMany: async (ids) => {
    const response = await ApiService.post(`${route}/many`, ids, { hideLoading: true });
    return response.data;
  },
  download: async (id) => {
    await ApiService.forceDownload(`${route}/${id}`);
  }
};
