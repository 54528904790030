import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const decodedTokens = {};

const tokenName = 'MRePlataformaToken';

const getToken = () => Cookies.get(tokenName);

const setToken = (token) => Cookies.set(tokenName, token, { expires: 0.5 });

const getDecodedToken = () => {
  const jwt = getToken();

  let decoded = decodedTokens[jwt];
  if (!decoded) {
    decoded = jwtDecode(jwt);
    decodedTokens[jwt] = decoded;
  }

  return decoded;
};

const getCurrentProfile = () => {
  const jwt = getDecodedToken();

  return jwt.auth;
};

const removeToken = () => Cookies.remove(tokenName);

const isAllowed = (...roles) => {
  const auth = getCurrentProfile();

  return roles.includes(auth);
};

export {
  getToken,
  setToken,
  removeToken,
  isAllowed,
};
