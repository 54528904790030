import Wicket from 'wicket';
import area from '@turf/area';

export default class MapaUtil {
  static getGeoJsonArea(geoJson) {
    return (area(geoJson) / 10000).toFixed(2);
  }

  static getWktArea(wkt) {
    const geom = new Wicket.Wkt();
    geom.read(wkt);

    const latlng = [];
    for (const coords of geom.components[0]) {
      latlng.push([coords.y, coords.x]);
    }

    const layer = L.polygon(latlng);

    return MapaUtil.getGeoJsonArea(layer.toGeoJSON());
  }
}
