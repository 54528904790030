import ApiService from './api.service';

const LoginService = {
  login: async (params) => {
    const response = await ApiService.post('api/authenticate', params);
    return response.data;
  },
};

export default LoginService;
