<template lang="pug">
auth(title='Não consigo entrar')
  template(v-slot:content)
    .message Para recuperar sua senha, siga as instruções enviadas no e-mail cadastrado para seu usuário.

    v-btn#btn-whats(block color="#4BBE99" @click="sendMessage")
      v-icon(left small) mdi-tag
      | Enviar e-mail
    .final-message Caso não tenha recebido o e-mail de recuperação de senha,
      | &nbsp;nos informe através do e-mail:
      b &nbsp;atendimento@smartcredit.agr.br
      | .
    .submit-container
      .btn-back(@click="goBack")
        v-icon#buttonBackLogin(color="#FFFFFF" small) mdi-arrow-left
        a#buttonBackLogin Voltar à tela inicial

</template>

<script>
import AccountService from '@/services/account.service';
import Auth from './Auth.vue';

export default {
  name: 'VerificacaoWhatsApp',
  components: {
    Auth
  },
  data() {
    return {
      cpfCnpj: '',
      phone: ''
    };
  },
  async mounted() {
    this.cpfCnpj = this.$route.params.cpfCnpj;
  // this.phone = await AccountService.get(this.cpfCnpj);
  },
  methods: {
    async sendMessage() {
      try {
        await AccountService.sendRecoverPasswordMessage(this.cpfCnpj);
        this.$notify({
          type: 'success',
          title: 'Sucesso',
          text: 'E-mail enviado com sucesso.',
        });
        this.goBack();
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: e.message || 'Problema ao enviar e-mail de recuperação de senha.',
        });
      }
    },
    goBack() {
      this.$router.push({
        name: 'Login',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  text-align: left;
  margin-top: 30px;
}

#cel {
  margin-top: 20px;
  display: flex;
}

.number-cel {
  color: #ffffff;
}

#btn-whats {
  color: #ffffff;
  margin: 23px 0px 23px 0px;
}

.final-message {
  font-size: 12.2px;
  text-align: start;
}
</style>
