export default {
  PRIMEIRO_ANO_PLANTIO: 1,
  POSSUI_SEGURO_AGRICOLA: 2,
  CULTURA_IMPLANTADA: 3,
  PRODUTOR_TEVE_PREJUIZOS: 4,
  SACAS_CPR: 5,
  VALOR_SACA_CPR: 6,
  AREA_CPR: 7,
  IDENTIFICACAO_TALHAO: 8,
  MUNICIPIO: 9,
  CULTURA: 10,
  PLANTIO: 11,
  AREA_IRRIGADA: 12,
  AREA: 13,
  TIPO_SOLO: 14,
  DATA_PLANTIO: 15,
  DATA_COLHEITA: 16,
  GRUPO: 17,
  PRODUTIVADADE_ANO: 18,
  ANO_PREJUIZO: 20,
  CAUSA_PREJUIZO: 21,
  AREA_PREJUIZO: 22,
  PRODUTIVIDADE_PREJUZIDO: 23,
  NOME_RESPONSAVEL: 24,
  CELULAR_RESPONSAVEL: 25,
  OBSERVACAO_RESPONSAVEL: 26,
  DATA_FIM_PLANTIO: 27
};
