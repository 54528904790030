<template lang='pug'>
div
  include ./talhoes.pug
</template>

<script>
import Mapa from '@/components/Mapa/Mapa.vue';
import AreaService from '@/services/area.service';
import FieldService from '@/services/field.service';
import FieldSituationEnum from '@/enums/FieldSituationEnum';
import QuestionEnum from '@/enums/QuestionEnum';
import AnswerUtil from '@/utils/answer.util';
import CatalogService from '@/services/catalog.service';
import ProcessService from '@/services/process.service';

export default {
  components: {
    Mapa
  },
  data() {
    return {
      area: {},
      loaded: false,
      code: this.$route.params.id,
      citiesSearched: {},
      FieldSituationEnum,
      endorsement: false
    };
  },
  async mounted() {
    this.area = await AreaService.getByProcess(this.code);

    const process = await ProcessService.getProcess(this.code);
    this.endorsement = process.cancelledByEndorsement;

    this.loadArea();
    this.loaded = true;
  },
  methods: {
    loadArea() {
      // Separado do for abaixo para da tempo de carregar poder centralizar nos talhões após ter adicionado
      // as geometrias no mapa corretamente.
      this.area.fields.forEach(async (field) => {
        const city = await this.getCityName(AnswerUtil.findValue(field.answers, QuestionEnum.MUNICIPIO));
        field.info = {
          ...(field.info || {}),
          city
        };
      });

      for (const field of this.area.fields) {
        field.area = AnswerUtil.findValue(field.answers, QuestionEnum.AREA);

        field.info = {
          ...(field.info || {}),
          planting: AnswerUtil.findValue(field.answers, QuestionEnum.PLANTIO),
          irrigatedArea: AnswerUtil.findValue(field.answers, QuestionEnum.AREA_IRRIGADA),
          area: field.area,
          group: AnswerUtil.findValue(field.answers, QuestionEnum.GRUPO)
        };
        field.leafletRef = `mapa_${field.id}`;
      }
    },
    async getCityName(cityId) {
      const citySearched = this.citiesSearched[cityId];
      if (citySearched) {
        return citySearched;
      }
      const city = await CatalogService.getCity(cityId);
      this.citiesSearched[cityId] = city.name;
      return city.name;
    },
    panelSelected(field) {
      if (field.isOpen !== undefined) {
        this.highlightTalhao(field);
      }
    },

    highlightTalhao(field) {
      this.$nextTick(() => {
        this.$refs[field.leafletRef][0].highlightTalhao();
      });
    },

    async contest(field) {
      try {
        const contestedField = await FieldService.contest(this.code, field.id);
        this.$notify({
          type: 'success',
          title: 'Sucesso',
          text: 'Favor entrar em contato com seu consultor para realizar os ajustes do polígono. Lembre-se de ter em mãos as informações do seu crédito.',
        });
        field.situation = contestedField.situation;
        const fieldIndex = this.area.fields.findIndex((f) => f.id === field.id);
        this.$set(this.area.fields, fieldIndex, field);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: e.message || 'Problema ao contestar o talhão.',
        });
      }
    },

    async confirm(field) {
      try {
        const confirmedField = await FieldService.confirm(this.code, field.id);
        this.$notify({
          type: 'success',
          title: 'Sucesso',
          text: 'Talhão confirmado com sucesso.',
        });
        field.situation = confirmedField.situation;
        const fieldIndex = this.area.fields.findIndex((f) => f.id === field.id);
        this.$set(this.area.fields, fieldIndex, field);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Erro',
          text: e.message || 'Problema ao confirmar o talhão.',
        });
      }
    },
  }
};
</script>
<style lang='scss' scoped>
.format-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.subtitle {
  color: #999999;
  margin-right: 5px;
  font-size: 12px;
}

.value {
  color: #333333;
}

.no-padding {
  padding: 0!important;
}

.situation-icon {
  display: block;
  width: 45px;
  margin: 0 auto;
  padding: 10px;
  font-size: 25px;
  border-radius: 100%;
  color: white;
}

.situation-text {
  margin: 20px 10px 0px 0px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.inline-flex {
  display: flex;
  flex-direction: row;
}

.field-info-box {
  .format-text {
    margin-top: 15px;
  }
}

.v-expansion-panel {
  margin-bottom: 20px;
}

.v-expansion-panel-content__wrap > .row {
  margin-top: 15px;
}

.merge-buttons {
  .col:nth-child(1) {
    .v-btn {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  .col:nth-child(2) {
    .v-btn {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
</style>
