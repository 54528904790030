<template lang='pug'>
.main
  v-container
    .card-container
      v-card.content-card.container#back(@click="back")
        .card
          v-icon(style='padding-right: 15px;') mdi-chevron-left
          div(style='width: 100%;', v-if='process')
            .content-top
              v-icon.icon(color='#FFFFFF', small, :class='endorsement ? "red-icon" : ""')
                | mdi-file-document
              .format-text
                .subtitle Cotação
                h5.value#codeProcess {{ process.code }}
              .format-text
                .subtitle Área (ha)
                h5.value {{ process.result.totalArea | decimal }}
            v-divider
            .content-bottom
              .flex-text
                .subtitle Emissão
                h6.value {{ process.emissionDate | dateTime }}
              .flex-text
                .subtitle Canal
                h6.value {{ channel ? channel.name : '-'}}
      v-tabs(v-model='tab' background-color='transparent' color='#398097' height='20')
        v-tabs-slider(color='#77c43e')
        v-tab.tab-label(v-for='(label, index) in tabs' :key='label' :id="`aba${index}`") {{ label }}
      //- Não remover o touchless se não quando o usuário está mover o mapa, as abas são alteradas.
      v-tabs-items(v-model='tab', touchless)
        v-tab-item
          dados
        v-tab-item
          documentos
        v-tab-item
          talhoes
        v-tab-item
          eventos

      .tab-title(v-if='!endorsement')
        v-btn#buttonRegisterNewEvent(block color="error" @click='comunicarEvento')
          v-icon(left) mdi-bullhorn-outline
          | Comunicar novo evento

</template>
<script>
import ProcessService from '@/services/process.service';
import ChannelService from '@/services/channel.service';
import Dados from './Dados.vue';
import Documentos from './Documentos.vue';
import Talhoes from './Talhoes.vue';
import Eventos from './Eventos.vue';

export default {
  components: {
    Dados,
    Documentos,
    Talhoes,
    Eventos
  },
  data() {
    return {
      code: this.$route.params.id,
      channel: null,
      tab: null,
      tabs: ['Dados', 'Documentos', 'Talhões', 'Eventos'],
      process: null,
      endorsement: false
    };
  },
  async mounted() {
    this.process = await ProcessService.getProcess(this.code);
    this.endorsement = this.process.cancelledByEndorsement;

    this.channel = await ChannelService.get(this.process.channelId);
    if (window.localStorage.getItem('eventRegistry')) {
      this.tab = 3;
      window.localStorage.removeItem('eventRegistry');
    }
  },
  methods: {
    comunicarEvento() {
      this.$router.push({ name: 'EventRegister', params: { id: this.code } });
    },

    back() {
      this.$router.push({ path: '/cotacoes' });
    },
  }
};
</script>
<style lang='scss' scoped>
@import '@/variables.scss';
.main {
  background-color: #f8fafb;
}

.v-tabs {
  margin: 30px 0 20px 0;
}

.v-tab {
  color: #77c43e;
  font-weight: 700;
  font-size: 11px;
  padding: 1px;
}

.v-window-item {
  background-color: #f8fafb;
}

.card {
  display: flex;
  flex-direction: row;
}
.content-card {
  padding: 10px 20px 10px 20px;
  margin-bottom: 15px;
  min-height: 50px;
}

.sub-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #398097;
  color: white;
  height: 60px;
  padding-left: 15px;
}

.container.text {
  display: flex;
  justify-content: flex-start;
  color: #ffffff;
  font-size: 12px;
}

.content-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.icon {
  background-color: #77c43e;
  height: 40px;
  width: 40px;
  border-radius: 100px;
}

.red-icon {
  background-color: $--error;
}

.format-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.subtitle {
  color: #999999;
  margin-right: 5px;
  font-size: 12px;
}

.value {
  color: #333333;
}

.content-bottom {
  display: flex;
  justify-content: space-between;
}

.flex-text {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.content-card {
  ul li {
    display: list-item;
  }
}

.v-btn {
  margin-top: 35px;
}
</style>

<style lang='scss'>
.v-slide-group__prev--disabled {
  display: none!important;
}
.v-slide-group__content.v-tabs-bar__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
