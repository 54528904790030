import ApiService from './api.service';

const route = '/services/process/api/field';
export default {
  contest: async (code, fieldId) => {
    const response = await ApiService.post(`${route}/${code}/contest/${fieldId}`);
    return response.data;
  },
  confirm: async (code, fieldId) => {
    const response = await ApiService.post(`${route}/${code}/confirm/${fieldId}`);
    return response.data;
  },
  list: async (code) => {
    const response = await ApiService.get(`${route}/${code}`);
    return response.data;
  }
};
