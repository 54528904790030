import Vue from 'vue';
import VueMask from 'v-mask';
import Notifications from 'vue-notification';
import App from '@/App.vue';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import ApiService from '@/services/api.service';
import { getToken, isAllowed } from '@/utils/security.util';
import config from '@/constants/config';
import Roles from '@/constants/roles';
import VMasker from 'vanilla-masker';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';

Vue.use(VueMask);
Vue.use(vuetify);
Vue.use(Notifications);

Vue.config.productionTip = false;
Vue.use(vuetify);

const checkToken = () => {
  let lastToken = getToken();
  let alreadyVerifiedRuralProducer = false;

  return () => {
    const currentToken = getToken();

    if (!currentToken) {
      const { allowAnonymous } = router.history.current.meta;
      if (allowAnonymous) {
        return;
      }
      window.location.href = config.BASE_URL;
      return;
    }

    if (currentToken !== lastToken) {
      alreadyVerifiedRuralProducer = false;
      lastToken = currentToken;
      if (isAllowed(Roles.SYS_ADMIN, Roles.CHANNEL_ADMIN, Roles.INSURER_ADMIN)) {
        window.location.href = config.BASE_URL_ADMIN;
      }
    }
    if (!alreadyVerifiedRuralProducer && isAllowed(Roles.CHANNEL_CONSULTANT, Roles.CHANNEL_REVIEWER, Roles.INSURER_ANALYST, Roles.MUNICH_RE_CONSULTANT)) {
      window.location.href = config.BASE_URL_APP;
    } else {
      alreadyVerifiedRuralProducer = true;
    }
  };
};

setInterval(checkToken(), 500);

router.beforeEach((to, from, next) => {
  const currentToken = getToken();
  const { allowAnonymous } = to.meta;
  if (!currentToken && !allowAnonymous) {
    window.location.href = config.BASE_URL;
  } else {
    next();
  }
});

Vue.filter('currency', (value, showPrefix = true) => {
  if (!value) {
    return '-';
  }

  return VMasker.toMoney(parseFloat(value).toFixed(2), {
    unit: showPrefix ? 'R$' : '',
    precision: 2,
    separator: ',',
    delimiter: '.',
  });
});

Vue.filter('dateTime', (value) => {
  if (value) {
    // eslint-disable-next-line no-undef
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
  }
  return null;
});

Vue.filter('date', (value) => {
  if (value) {
    return moment.utc(value).format('DD/MM/YYYY');
  }
  return null;
});

Vue.filter('time', (value) => {
  if (value) {
    return moment(value).format('HH:mm');
  }
  return null;
});

Vue.filter('phone', (value) => {
  if (!value) {
    return '-';
  }

  return value.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3');
});

Vue.filter('decimal', (value, suffix = '') => {
  if (value === undefined || value === null) {
    return '-';
  }

  const numberValue = Number(value);

  if (Number.isNaN(numberValue)) {
    return value;
  }

  const number = numberValue.toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
  });

  if (suffix) {
    return `${number} ${suffix}`;
  }

  return number;
});

Vue.filter('cpfCnpj', (value) => {
  if (!value) {
    return '-';
  }
  if (value.length > 11) {
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }
  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
});

ApiService.init();

new Vue({
  router,
  render: (h) => h(App),
  vuetify,
  VueMask,

  components: {
    Notifications,
  },
}).$mount('#app');
